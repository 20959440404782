import $, { extend } from 'jquery';
import React,{useState} from 'react';
const Comp_Cms_Data_Mapping=(props)=>{
    //alert(JSON.stringify(props.editData));
    const [getCompList,setCompList]=useState([]);
    const handleMapMenuChg=(event)=>{
        let tarValue=event.target.value;
        if(tarValue!=="0" && tarValue!==0){
            tarValue=atob(tarValue);
        }
        const listFltr = d =>d.parent_id===tarValue;
        let listComps=props.listAllComp.filter(listFltr);
        setCompList(listComps);
    }
    
        return(<div className="modal fade bd-example-modal-xl"  id="divCmsDataMapp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xlll" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Data Mapping</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <form id="frmCmsDataMapp" name="frmCmsDataMapp">
           
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                  <input type="hidden" name="res_func" id="res_func" value="okSuccMapping" />
                                  <input type="hidden" name="curl_type" id="curl_type" value="b_userEntryNew" />
                                  <input type="hidden" name="m_type" id="m_type" value="b_userEntryNew" />
                                  <input type="hidden" name="type_id" id="type_id" value="2" />
                                  <input type="hidden" name="data_id_col1" id="data_id_col1" value={typeof props.editData.id!=="undefined"?btoa(props.editData.id):'0'}/>
                                  <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.user_object[0].id} />
                                  <input type="hidden" name="added_by_id" id="added_by_id" value={props.user_object[0].app_user_id}/>
                                  <input type="hidden" name="mapp_type" id="mapp_type" value={btoa(11)}/>
                                  <div className="card-body">
                                      <div className="row">
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Menu *</label>
                                                  <div className="input-group input-group-sm">
                                                      <select onChange={handleMapMenuChg} id="map_menu_col4" name="map_menu_col4" className="form-control required">
                                                        <option value="0">-Menu-</option>
                                                        {props.listMenus.map((item,index)=>{
                                                          return (<option value={btoa(item.id)}>{item.col1}</option>)
                                                        })}
                                                      </select>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Select Component *</label>
                                                  <div className="input-group input-group-sm">
                                                      <select id="map_comp_col5" name="map_comp_col5" className="form-control required">
                                                        {getCompList.length>0?<option value="0">-Component-</option>:
                                                          <option value={"-1"}>-No Component-</option>
                                                        }
                                                        
                                                        
                                                        {getCompList.map((item,index)=>{
                                                          return (<option value={btoa(item.id)}>{item.col1}</option>)
                                                        })}
                                                      </select>
                                                  </div>
                                              </div>
                                          </div>
                                         
                                  
                                      </div>
                                    
                                      <div className="col-md-6 respSSL"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                              
                          </div>
                      </div>
                  </div>
            </div>
            <div className="modal-footer">
            <div id="getReponse" style={{color:"red"}}></div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" onClick={()=>props.pAddNewMapping("frmCmsDataMapp")} className="btn _btnSetColor1212 btn-primary">Save changes</button>
            </div>
            </form>
          </div>
          </div>
          </div>);
    
}
export default Comp_Cms_Data_Mapping;
